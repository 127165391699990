import * as ConfirmOTPActionCreators from './OTP/confirmOTP';
import * as InitOperatorActionCreators from './Operator/operator';
import * as GetHistoryActionCreators from './History/history';
import * as InitPlayerActionCreators from './Player/player';
import * as SmartJackpotActionCreators from './SmartBet/smartBet';
import * as SmartJackpotsActionCreators from './SmartBet/smartBets';
import * as trackingSmartJackpotsActionCreators from './SmartBet/smartbetTracking';
import * as GenerateSmartJackpotActionCreators from './SmartBet/generateSmartBet';
import * as quickbetActionCreators from './Quickbet/quickbet';
import * as quickbetsActionCreators from './Quickbet/quickbets';
import * as quicktipActionCreators from './Quicktip/quicktip';
import * as quicktipsActionCreators from './Quicktip/quicktips';
import * as createQuicktipActionCreators from './Quicktip/quicktipCreate';
import * as reserveQuicktipActionCreators from './Quicktip/quicktipReserve';
import * as trackingQuicktipActionCreators from './Quicktip/quicktipTracking';
import * as superbetsActionCreators from './Superbet/superbets';
import * as superbetActionCreators from './Superbet/superbet';
import * as superbetTrackingActionCreators from './Superbet/superbetTracking';
import * as calculationTicketsValueActionCreators from './Calculation/calculation';



export default {
    ...ConfirmOTPActionCreators,
    ...SmartJackpotActionCreators,
    ...SmartJackpotsActionCreators,
    ...GenerateSmartJackpotActionCreators,
    ...trackingSmartJackpotsActionCreators,
    ...quicktipActionCreators,
    ...quicktipsActionCreators,
    ...createQuicktipActionCreators,
    ...reserveQuicktipActionCreators,
    ...trackingQuicktipActionCreators,
    ...InitOperatorActionCreators,
    ...InitPlayerActionCreators,
    ...superbetsActionCreators,
    ...superbetActionCreators,
    ...superbetTrackingActionCreators,
    ...quickbetActionCreators,
    ...quickbetsActionCreators,
    ...GetHistoryActionCreators,
    ...calculationTicketsValueActionCreators
};