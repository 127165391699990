import React, { FC, useEffect, useState } from 'react';
import { QuickTipsList, calculateTotalOdds, formatCountdown } from "./helper";
import { Link } from "react-router-dom";
import {Alert} from "react-bootstrap";

interface Props {
    data: QuickTipsList[];
    atagParams: string | null
}
interface Countdown {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}
type CountdownOrExpired = Countdown | "Expired";
const QuicktipLobbyComponent: FC<Props> = ({ data, atagParams }) => {
    const [countdowns, setCountdowns] = useState<{ [key: string]: CountdownOrExpired }>({});
    useEffect(() => {
        const intervals: NodeJS.Timeout[] = [];
        const updatedCountdowns: { [key: string]: CountdownOrExpired } = {};
        data.forEach((item, index) => {
            const countdown = formatCountdown(item.expires);
            updatedCountdowns[item.code] = countdown;
            if (typeof countdown !== "string") {
                const intervalId = setInterval(() => {
                    const updatedCountdowns = { ...countdowns };
                    updatedCountdowns[item.code] = formatCountdown(item.expires);
                    setCountdowns(updatedCountdowns);
                }, 1000);
                intervals.push(intervalId);
            }
        });

        setCountdowns(updatedCountdowns);
        return () => {
            intervals.forEach((intervalId) => clearInterval(intervalId));
        };
    }, [data]);
    return (
        <div className="col-12">
            {
                data.length > 0 ?
                    <div className="card quicktip-lobby-card mt-3">
                        <div className="card-header" style={{color: '#005A70', fontWeight: '700'}}>
                            Quick tips
                        </div>
                        <ul className="list-group list-group-flush">
                            {data.map((item, index) => {
                                const countdown = formatCountdown(item.expires)
                                return (
                                    <li className={`list-group-item ${index % 2 === 1 ? 'list-item-bg-dark' : 'list-item-bg-light'}`}
                                        key={index}>
                                        <Link
                                            to={atagParams ? `quicktip/${item.code}?atag=${atagParams}` : `quicktip/${item.code}`}
                                            className='d-flex justify-content-between align-items-center text-decoration-none text-green'
                                        >
                            <span>
                                 <b>{item.events.length} events</b> <br/>
                                {calculateTotalOdds(item.events)} <b>Odds</b>
                            </span>
                                            <span className='quicktip-title'>
                                {item.title}
                            </span>
                                            <span className='expires d-flex align-content-center'>
                                        <i className="far fa-clock m-2"/>
                                        <span className='d-flex flex-column align-content-center expires-time'>
                                            <div>
                                                    {
                                                        countdown !== 'Expired' ? (
                                                            <span>
                                                               {
                                                                   countdown.days > 0 ?
                                                                       countdown.days + ' Days'
                                                                       : 'Today in'
                                                               }
                                                            </span>
                                                        ) : null
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        countdown !== 'Expired' ? (
                                                            <span>
                                                               {
                                                                   `${countdown.hours}:${countdown.minutes}:${countdown.seconds}'`
                                                               }
                                                            </span>
                                                        ) : null
                                                    }
                                                </div>
                                        </span>
                                    </span>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>

                    </div>
                    : <Alert>Loading...</Alert>
            }
        </div>
    );
}

export default QuicktipLobbyComponent;
