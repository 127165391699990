interface QuickTipEventCategory {
    league_id: number;
    league_slug: string;
    league_name: string;
    country_id: number;
    country_slug: string;
    country_name: string;
}

interface QuickTipTeam {
    slug: string;
    name: string;
    id: number;
}

interface QuickTipBet {
    id: number;
    bet_code: string;
    bet_name: string;
    market_code: string;
    market_name: string;
    line_code: string | null;
    line_name: string | null;
    odds: number;
    updated: string;
}

interface QuickTipEvent {
    starts: string;
    category: QuickTipEventCategory;
    home_team: QuickTipTeam;
    away_team: QuickTipTeam;
    bet: QuickTipBet;
    id: number;
}

export interface QuickTipsList {
    code: string;
    stakes: number[];
    enabled: boolean;
    title: string;
    created: string;
    expires: string;
    events: QuickTipEvent[];
}

export const calculateTotalOdds = (events: QuickTipEvent[]) => {

    let totalOdds = 1;
    for (const event of events) {
        totalOdds *= event.bet.odds;
    }

    return parseFloat(totalOdds.toFixed(2));
}

export const formatCountdown = (expires: string) => {
    const expiryDate = new Date(expires);
    const now = new Date();
    const diff: number = expiryDate.getTime() - now.getTime();

    if (diff <= 0) {
        return "Expired";
    }

    const seconds: number = Math.floor(diff / 1000);
    const days: number = Math.floor(seconds / (3600 * 24));
    const hours: number = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    const remainingSeconds: number = seconds % 60;

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: remainingSeconds
    };
}

/// Smartbet
interface SmartbetMarket {
    code: string;
    lines: string[];
    bets: string[];
}

interface SmartbetRiskTips {
    min_odds: number;
    max_odds: number;
}

export interface Smartbet {
    code: string;
    stakes: number[];
    enabled: boolean;
    title: string;
    potential_wins: number[];
    multibonus_odds: number;
    min_odds: number;
    max_odds: number;
    medium_risk_tips: SmartbetRiskTips;
    low_risk_tips: SmartbetRiskTips;
    markets: SmartbetMarket[];
    events_start_before: number;
    leagues: number[];
    created: string;
}

///Superbet

interface SuperbetEventCategory {
    league_id: number;
    league_slug: string;
    league_name: string;
    country_id: number;
    country_slug: string;
    country_name: string;
}

interface SuperbetTeam {
    slug: string;
    name: string;
    id: number;
}

interface SuperbetBet {
    id: number;
    bet_code: string;
    bet_name: string;
    odds: number;
    updated: string;
}

interface SuperbetMarket {
    market_code: string;
    market_name: string;
    line_code: string | null;
    line_name: string | null;
    bets: SuperbetBet[];
}

interface SuperbetEvent {
    id: number;
    starts: string;
    category: SuperbetEventCategory;
    home_team: SuperbetTeam;
    away_team: SuperbetTeam;
    markets: SuperbetMarket[];
}

export interface Superbet {
    code: string;
    stakes: number[];
    is_free: boolean;
    enabled: boolean;
    title: string;
    created: string;
    expires: string;
    event: SuperbetEvent;
}